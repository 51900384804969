import { getStringCookie, getJacketSkuFromSkuArray } from './utils';
import { Filter, Item, Bundle, Visualizer } from '../types';

const segmentIsLoaded = () => window.analytics !== undefined;

const trackFn = (action: string, data: unknown = null, i = 0) => {
  if (segmentIsLoaded()) {
    if (data === null) {
      window.analytics.track(action);
    } else {
      window.analytics.track(action, data);
    }

    return true;
  }

  if (i < 5) {
    setTimeout(() => trackFn(action, data, i + 1), 500);
  }

  return false;
};

const track = {
  product: {
    viewed: (data: unknown = null) => trackFn('Product Viewed', data),
  },
  user: {
    loggedIn: (data: unknown = null) => trackFn('User Logged In', data),
    cxLoggedIn: (data: unknown = null) => trackFn('CX Logged In As Customer', data),
  },
  productList: {
    viewed: (data: unknown = null) => trackFn('Product List Viewed', data),
    filtered: (data: unknown) => trackFn('Product List Filtered', data),
  },
};

export const getSkus = (product: Item) => {
  if (product.type === 'tuxedo' || product.type === 'suit') {
    // If product is a tuxedo or a suit, we need the jacket sku.
    const jacketSku = getJacketSkuFromSkuArray((product as Bundle).products!.map((p) => p.sku));
    return [jacketSku];
  } else if (product.type === 'visualizer') {
    return (product as Visualizer).skus;
  }
  return [product.sku];
};

export const getProductImageUrl = (product: Item) => {
  const media = product.media || [];

  const image = media.filter((image) => {
    const label = image.label || '';

    return label.toLowerCase().includes('pdp_hero') || label.toLowerCase().includes('layflat');
  });

  image.push({
    url: 'https://gentux.imgix.net/no-image.png',
  });

  return image[0].url;
};

export const productListViewed = (products: Item[], productCategory: string) => {
  try {
    const productsViewed = products.map((product) => {
      const skus = getSkus(product);

      return {
        product_id: product.id,
        name: product.displayName,
        sku: skus.join(),
        category: product.category,
        price: parseFloat(product.cost!).toFixed(2),
        currency: 'USD',
        brand: 'Menguin',
        image_url: getProductImageUrl(product),
      };
    });

    track.productList.viewed({
      list_id: productCategory,
      products: productsViewed,
      category: productCategory,
    });
  } catch (e) {
    console.error(e);
  }
};

export const productListFiltered = (category: string, filters: Filter, products: Item[]) => {
  try {
    const filteredProducts = products.map((product) => ({
      product_id: product.id,
      name: product.displayName,
      sku: getSkus(product).join(),
      category: product.category,
      price: parseFloat(product.cost!).toFixed(2),
      currency: 'USD',
      brand: 'Menguin',
      image_url: getProductImageUrl(product),
    }));

    let filterList = [];

    filters.colors.length > 0 &&
      filterList.push({
        type: 'color',
        value: filters.colors.join(),
      });

    filters.patterns.length > 0 &&
      filterList.push({
        type: 'pattern',
        value: filters.patterns.join(),
      });

    filters.categories.length > 0 &&
      filterList.push({
        type: 'category',
        value: filters.categories.map((filter) => filter.displayName).join(),
      });

    filters.procurement.length > 0 &&
      filterList.push({
        type: 'procurement',
        value: filters.procurement.join(),
      });

    filters.tieTypes.length > 0 &&
      filterList.push({
        type: 'tieTypes',
        value: filters.tieTypes.join(),
      });

    track.productList.filtered({
      filters: filterList,
      category: category,
      products: filteredProducts,
    });
  } catch (e) {
    console.error(e);
  }
};

/**
 * @todo Determine whether this function *needs* to receive arguments - historically it hasn't had
 *  parameters in its signature, but its one usage in `auth.ts` calls it with four that I assume are
 *  being silently swallowed at runtime?
 */
export const loggedIn = (..._: unknown[]) => {
  try {
    track.user.loggedIn({
      campaignId: getStringCookie('iterableEmailCampaignId') !== undefined ? Number(getStringCookie('iterableEmailCampaignId')) : null,
    });
  } catch (e) {
    console.error(e);
  }
};

export const productsViewed = (skus: string[], data: unknown = null) => {
  try {
    track.product.viewed(data ?? { sku: skus });
  } catch (e) {
    console.error(e);
  }
};

export const trackCxLogin = (data: unknown) => {
  try {
    track.user.cxLoggedIn({ data });
  } catch (e) {
    console.error(e);
  }
};
