import { ComponentProps, ComponentType, createContext, Fragment, ReactNode, useContext } from 'react';
import Link from 'next/link';
import BasePortableText from 'react-portable-text';
import type {
  Button as ButtonType,
  Video as VideoType,
  Picture,
  SalesCallout,
  CheckedList,
  CheckedListItem,
  ThreeImageCollage,
  Space,
  FaqSection as FAQSectionType,
  SplitColorBlock,
  LayoutFaq as LayoutFaqType,
  FaqItem as FaqItemType,
  IconList as IconListType,
  Bundle as SanityBundle,
  ProductCards,
  ProductCard as ProductCardType,
  SalesCallouts3x1,
  ContentBlock,
  Layout1x1 as Layout1x1Type,
} from '@root/sanity/sanity.types';
import { urlFor } from '@root/lib/sanity';
import DoubleHeader from 'components/DoubleHeader';
import FaqItem from 'components/FaqItem';
import FeaturedLinks from 'components/FeaturedLinks';
import HTODisclaimer from 'components/HTODisclaimer';
import Layout1x1 from 'components/Layout1x1';
import Layout2x1 from 'components/Layout2x1';
import Layout2x1ImageLeft from 'components/Layout2x1ImageLeft';
import Layout2x1ImageRight from 'components/Layout2x1ImageRight';
import LayoutFaq from 'components/LayoutFaq';
import Line from 'components/Line';
import NextSteps from 'components/NextSteps';
import NumberedList from 'components/NumberedList';
import Quote from 'components/Quote';
import SalesCalloutGroomsFree from 'components/SalesCalloutGroomsFree';
import SalesCalloutHTO from 'components/SalesCalloutHTO';
import SalesCalloutInspiration from 'components/SalesCalloutInspiration';
import SalesCalloutSwatches from 'components/SalesCalloutSwatches';
import SanityPicture from 'components/SanityPicture';
import SwatchesCard, { SwatchesCardProps } from 'components/SwatchesCard';
import ColorOfTheYear from 'components/ColorOfTheYear';
import IconCheck from 'components/IconCheck';
import IconX from 'components/IconX';
import FAQSection from 'components/FAQSection';
import Video from 'components/Video';
import IconList from 'components/IconList';
import IconArrowRight from 'components/IconArrowRight';
import { Bundle } from 'types';
import { ProductCard } from 'components/ProductCard';

export type PortableTextProps = ComponentProps<typeof BasePortableText>;

export type Serializers = {
  [key: string]: ComponentType<any>;
};

/**
 * Additional data that exists outside of Sanity and should be available to serializers
 */
export type SerializerData = {
  bundles: Bundle[];
};

type SerializerOptions = {
  data: SerializerData;
  serializers?: Serializers;
};

export const SerializerContext = createContext<SerializerOptions>({
  data: {
    bundles: [],
  },
});

/**
 * Wraps `<PortableText />` with default values for certain props
 *
 * `ignoreUnknownTypes` is set to `true` so we can conditionally disable "unknown
 * type" exceptions via the `unknownType` serializer. This will allow us to test
 * new block types locally without affecting other developers or the CI pipeline.
 *
 * `serializers` provides the component serializers that are defined below.
 */
export const PortableText = (props: PortableTextProps & { children?: any }) => {
  const context = useContext(SerializerContext);

  const serializers = {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    ...components,
    ...(context.serializers ?? {}),
    ...(props.serializers ?? {}),
  };

  return <BasePortableText className={props.className} ignoreUnknownTypes={true} serializers={serializers} content={props.content} />;
};

/**
 * Returns a boolean indicating whether an unknown block type should cause an
 * exception to be thrown
 *
 * #### Behavior by environment
 * |                | Client                              | Server                                |
 * |----------------|-------------------------------------|---------------------------------------|
 * | **Local**      | Shows an error on the page          | Logs a warning to the terminal        |
 * | **QA**         | Logs an error to the console        | Logs a warning to the terminal        |
 * | **Production** | Logs an error to the console [1]    | Throws an exception and stops a build |
 *
 * [1] This condition will never be reached since the build will fail and the site
 *     won't be deployed, but if it was reached then this is how it would behave.
 */
export const shouldThrowOnUnknownType = () => typeof window !== 'undefined' || process.env.ENVIRONMENT == 'production';

const getSalesCallout = (salesCallout: SalesCallout, ctaPageName: string) => {
  switch (salesCallout.type) {
    case 'groomsFree':
      return <SalesCalloutGroomsFree ctaPageName={ctaPageName} />;
    case 'hto':
      return <SalesCalloutHTO ctaPageName={ctaPageName} />;
    case 'swatches':
      return <SalesCalloutSwatches ctaPageName={ctaPageName} />;
    case 'inspiration':
      return <SalesCalloutInspiration ctaPageName={ctaPageName} />;
  }
};

export const components = {
  unknownType: (props: any) => {
    if (shouldThrowOnUnknownType()) {
      const blockType = props.node?._type ?? "Couldn't determine name of type";

      throw new Error(`No serializer is defined for the following type: ${blockType}`);
    }

    return null;
  },
  button: (props: ButtonType) => {
    const { href = '#' } = props;

    if (!props.text) {
      return null;
    }

    return (
      <Link href={href}>
        <a className={`btn ${props.style} ${props.trackerClass ?? ''} ${props.fullWidth ? 'w-full' : ''}`}>
          {props.arrowIcon ? <IconArrowRight /> : ''}
          {props.text}
        </a>
      </Link>
    );
  },
  bullet: (props: any) => <ul className="mb-16">{props.children}</ul>,
  picture: (props: any) => (
    <>
      {props.asset && !props.asIs ? (
        <SanityPicture
          alt={props.alt}
          className={props.aspectRatio === 'auto' ? '' : `aspect-ratio-${props.aspectRatio}`}
          crop={props.cropOption}
          hero={props.hero}
          polaroid={props.polaroid}
          url={urlFor(props.asset).url()}
        />
      ) : (
        props.asset && props.asIs && <img src={urlFor(props.asset).url()} />
      )}
    </>
  ),
  layout1x1: (props: Layout1x1Type) => {
    const padding = `${props.spaceTop === 'collapsed' ? 'pt-0' : ''} ${props.spaceBottom === 'collapsed' ? 'pb-0' : ''}`;

    if (props.centered) {
      return (
        <div className={`${padding.trim()} container`}>
          <div className="row">
            <div className="col-start-2 col-end-12 space-y-16 xs:col-span-8 xs:col-start-3 lg:col-start-4 lg:col-end-10">
              <div
                className={`w-full space-y-24 ${props.backgroundColor} px-32 py-64 xs:px-128 sm:px-16 sm:py-32 md:px-32 lg:px-128 lg:py-64`}
              >
                {props.children && <PortableText content={props.children} />}
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className={`${props.hero ? 'p-section-hero' : 'p-section'} ${padding.trim()} ${props.backgroundColor}`}>
        <Layout1x1 hero={props.hero}>{props.children && <PortableText content={props.children} />}</Layout1x1>
      </div>
    );
  },
  layout2x1: (props: any) => (
    <div
      className={`p-section ${props.spaceTop === 'collapsed' ? ' pt-0' : ''}${props.spaceBottom === 'collapsed' ? ' pb-0' : ''} ${
        props.backgroundColor
      }`}
    >
      <Layout2x1
        block1={<PortableText content={props.block1} serializers={components} key={props.block1._key} />}
        block2={<PortableText content={props.block2} serializers={components} key={props.block2._key} />}
      >
        {props.children?.map((item: any) => (
          <PortableText content={item} serializers={components} key={item._key} />
        ))}
      </Layout2x1>
    </div>
  ),
  layout2x1ImageLeft: (props: any) => (
    <div
      className={`${props.hero ? 'p-section-hero' : 'p-section'}${props.spaceTop === 'collapsed' ? ' pt-0' : ''}${
        props.spaceBottom === 'collapsed' ? ' pb-0' : ''
      } ${props.backgroundColor}`}
    >
      <Layout2x1ImageLeft hero={props.hero} image={<PortableText content={props[`${props.mediaType}`]} />}>
        {props.children && <PortableText content={props.children} />}
      </Layout2x1ImageLeft>
    </div>
  ),
  layout2x1ImageRight: (props: any) => (
    <div
      className={`${props.hero ? 'p-section-hero' : 'p-section'}${props.spaceTop === 'collapsed' ? ' pt-0' : ''}${
        props.spaceBottom === 'collapsed' ? ' pb-0' : ''
      } ${props.backgroundColor}`}
    >
      <Layout2x1ImageRight hero={props.hero} image={<PortableText content={props[`${props.mediaType}`]} />}>
        {props.children && <PortableText content={props.children} />}
      </Layout2x1ImageRight>
    </div>
  ),
  numberedList: (props: any) => {
    const items = props.items.map(({ content, headerText, image }: any) => ({
      content: <PortableText content={content} />,
      headerText,
      image,
    }));

    return <NumberedList items={items} />;
  },
  faqItem: (props: FaqItemType) => (
    <FaqItem question={props.question} trackerClass={props.trackerClass ?? ''}>
      {props.children && <PortableText content={props.children} />}
    </FaqItem>
  ),
  layoutFaq: (props: LayoutFaqType) => (
    <div
      className={`p-section${props.spaceTop === 'collapsed' ? ' pt-0' : ''}${props.spaceBottom === 'collapsed' ? ' pb-0' : ''} ${
        props.backgroundColor
      }`}
    >
      <LayoutFaq
        faqSingle={props.faqSingle && <PortableText content={props.faqSingle} />}
        title={props.title}
        cta={props.cta?.href && <PortableText content={[props.cta]} />}
        faqItems={props.faqItems && <PortableText content={props.faqItems} />}
        full={props.full}
      >
        {props.children && <PortableText content={props.children} />}
      </LayoutFaq>
    </div>
  ),
  swatchesCard: (props: any) => {
    const items: SwatchesCardProps['items'] =
      props.items?.map(({ image }: { image: Picture }) => ({
        image: {
          alt: image.alt || '',
          url: urlFor(image.asset).url(),
        },
      })) || [];

    const image: SwatchesCardProps['image'] = {
      alt: props.image.alt,
      url: urlFor(props.image.asset).url(),
    };

    return (
      <SwatchesCard image={image} items={items}>
        {props.children?.map((item: any) => (
          <PortableText content={item} serializers={components} key={item._key} />
        ))}
      </SwatchesCard>
    );
  },
  featuredLinks: (props: any) => {
    const items =
      props.items?.map((item: { copy: string; image: Picture; url: string }) => ({
        copy: item.copy,
        image: {
          alt: item.image.alt,
          url: urlFor(item.image.asset).url(),
        },
        url: item.url,
      })) || [];

    return (
      <FeaturedLinks items={items}>
        {props.children?.map((item: any) => (
          <PortableText content={item} serializers={components} key={item._key} />
        ))}
      </FeaturedLinks>
    );
  },
  colorOfTheYear: (props: any) => (
    <ColorOfTheYear
      description={props.description && <PortableText content={props.description} />}
      image={{ alt: props.image.alt, url: urlFor(props.image.asset).url() }}
      title={props.title && <PortableText content={props.title} />}
    />
  ),
  nextSteps: (props: any) => (
    <>
      <NextSteps
        header={props.header}
        description={props.description}
        topButton={props.topButton && <PortableText content={props.topButton} />}
        bottomButton={props.bottomButton && <PortableText content={props.bottomButton} />}
        ctaPageName={props.ctaPageName}
        salesCallout1={getSalesCallout(props.salesCallout1, props.ctaPageName)}
        salesCallout2={getSalesCallout(props.salesCallout2, props.ctaPageName)}
      />
      {props.salesCallout1.type === 'hto' || props.salesCallout2.type === 'hto' ? <HTODisclaimer /> : null}
    </>
  ),
  doubleHeader: (props: any) => {
    let smallTitle: string | ReactNode = props.smallTitle;
    let largeTitle: string | ReactNode = props.largeTitle;

    if (props.useRichText) {
      smallTitle = <PortableText content={props.smallTitleRichText} />;
      largeTitle = <PortableText content={props.largeTitleRichText} />;
    }

    return <DoubleHeader hero={props.hero} smallTitle={smallTitle} largeTitle={largeTitle} />;
  },
  quote: (props: any) => (
    <Quote citationName={props.citationName} citationTitle={props.citationTitle}>
      {props.quote}
    </Quote>
  ),
  normal: ({ children }: any) => <p className="mb-16">{children}</p>,
  nospace: ({ children }: any) => <p>{children}</p>,
  sm: ({ children }: any) => <p className="mb-16 text-sm">{children}</p>,
  h1: ({ children }: any) => <h1 className="text-h1 mb-16">{children}</h1>,
  h2: ({ children }: any) => <h2 className="text-h2 mb-16">{children}</h2>,
  h3: ({ children }: any) => <h3 className="text-h3 mb-16">{children}</h3>,
  h4: ({ children }: any) => <h4 className="text-h4 mb-16">{children}</h4>,
  h1_display: ({ children }: any) => <h1 className="text-h1-display">{children}</h1>,
  h2_display: ({ children }: any) => <h2 className="text-h2-display">{children}</h2>,
  h3_display: ({ children }: any) => <h3 className="text-h3-display">{children}</h3>,
  h4_display: ({ children }: any) => <h4 className="text-h4-display">{children}</h4>,
  line: () => <Line />,
  contentBlock: (props: ContentBlock) => (
    <div className={props.clsx ?? ''}>
      <PortableText content={props.children ?? []} />
    </div>
  ),
  styleBlock: (props: any) => {
    const renderItems = () => props.pageBuilder.map((item: any) => <PortableText className={item.clsx} content={item} key={item._key} />);

    const wrapperClsx = [props.style, props.backgroundColor];

    const containerClsx = [];
    if (props.container) containerClsx.push('container');
    if (props.as === 'grid') containerClsx.push('row');

    return (
      <div className={wrapperClsx.join(' ').trim()}>
        <div className={containerClsx.join(' ')}>
          {props.as === 'grid' ? <div className={props.gridClsx}>{renderItems()}</div> : renderItems()}
        </div>
      </div>
    );
  },
  link: (props: any) => (
    <Link href={props.href}>
      <a className={props.trackerClass ?? ''}>{props.children}</a>
    </Link>
  ),
  checkedList: (props: CheckedList) => (
    <ul className="list-none space-y-16">
      {props.items.map((item: CheckedListItem) => (
        <li className="flex items-center gap-16">
          {item.checked ? <IconCheck /> : <IconX />} {item.text}
        </li>
      ))}
    </ul>
  ),
  threeImageCollage: (props: ThreeImageCollage) => (
    <div className="grid grid-cols-2 gap-32">
      <div className="col-span-1 flex flex-col gap-32">
        <PortableText content={[props.imageOne, props.imageTwo]} />
      </div>
      <PortableText content={[props.imageThree]} />
    </div>
  ),
  space: (props: Space) => <div className={props?.verticalSpacingSize} />,
  faqSection: (props: FAQSectionType) => (
    <div className="row">
      <div className="col-start-1 col-end-13 xs:col-start-2 sm:col-end-12">
        <div className="space-y-32 bg-white p-32 shadow-3 xs:px-64 sm:py-64">
          <h2 className={props.headingStyle}>{props.heading}</h2>
          <FAQSection pageName={props.pageName} />
          <PortableText content={[props.button]} />
        </div>
      </div>
    </div>
  ),
  splitColorBlock: (props: SplitColorBlock) => {
    const options: { [key: string]: string } = {
      above: `-mt-${props.size} ${props.backgroundColor} pb-${props.size}`,
      below: `-mb-${props.size} ${props.backgroundColor} pt-${props.size}`,
    };
    return <div className={options[props.position]} />;
  },
  video: (props: VideoType) => <Video title={props.title} url={props.url!} videotype={props.videotype} />,
  iconList: (props: IconListType) => {
    if (!props?.iconListItems) return null;

    return (
      <IconList
        heading={<PortableText content={props.heading} />}
        button={<PortableText content={[props.button]} />}
        iconListItems={props.iconListItems}
      />
    );
  },
  salesCallouts3x1: (props: SalesCallouts3x1) => (
    <div className="m-section container">
      <div className="row mb-32 gap-32">
        {props.callouts.map((callout: any, i: number) => (
          <div
            className={`col-span-12 flex items-stretch xs:col-span-6 sm:col-span-4 lg:col-span-3 ${i === 0 ? 'lg:col-start-3' : ''}`}
            key={`callout-${i}`}
          >
            {getSalesCallout(callout, props.ctaPageName)}
          </div>
        ))}
      </div>
    </div>
  ),
  productCard: (props: ProductCardType) => {
    const { data } = useContext(SerializerContext);

    // Note: We have to convert it this way to prevent TypeScript from complaining,
    // but it's safe because we dereference the bundle in the GROQ query
    const item = props.item as unknown as SanityBundle;

    const bundleId = Number(item.bundleId?.current);

    const bundle = data.bundles.find((b) => b.id === bundleId);

    if (!bundle) {
      throw new Error(`Page references a bundle that no longer exists: ${bundleId}`);
    }

    let image: { url: string; alt?: string } | null = null;

    if (props.image) {
      image = {
        url: urlFor(props.image.asset).url(),
        alt: props.image.alt,
      };
    }

    return (
      <div className="col-span-6 flex flex-col md:col-span-3">
        <ProductCard item={bundle} image={image} />
      </div>
    );
  },
  productCards: (props: ProductCards) => (
    <div className="m-section container">
      <h2 className="text-h1 mb-32 whitespace-pre-line">{props.heading}</h2>

      <div className="row">
        <PortableText className="row col-start-1 col-end-13 gap-y-32 md:col-start-2" content={props.cards} />
        {props.showCta && (
          <div className="row col-start-1 col-end-13 gap-y-32 md:col-start-2">
            <div className="col-span-12 mt-32">
              <PortableText content={[props.cta!]} />
            </div>
          </div>
        )}
      </div>
    </div>
  ),
  collectionPageContents: () => null,
};
