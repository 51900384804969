import { ProductAssociation } from '../../server/services/cms/Cms';
import { getLocalURL } from '../utils/utils';
import { ProductReview, BottomLine, ProductReviewPagination, JsonResponse, Item, Bundle, Product } from '../types';

export interface ProductReviewsQueryResponse {
  productReviews: {
    reviews: ProductReview[];
    bottomLine: BottomLine;
    pagination: ProductReviewPagination;
  };
}

export interface SiteReviewsQueryResponse {
  siteReviews: {
    reviews: ProductReview[];
    bottomLine: BottomLine;
  };
}

const productsApi = {
  async getProductReviews(page: number, bundleId: number): Promise<ProductReviewsQueryResponse> {
    const response = await fetch(`/api/get-product-reviews`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: bundleId,
        perPage: 3,
        page: page,
      }),
    });
    const reviews = await response.json();
    return reviews;
  },
};

export const getProductAssociations = async (productId: number): Promise<JsonResponse<ProductAssociation[]>> =>
  await fetch(`${getLocalURL()}/api/get-product-associations`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      productId: productId,
    }),
  });

export const getProducts = async (category: string): Promise<JsonResponse<Item[]>> =>
  await fetch(`${getLocalURL()}/api/collection`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      category,
    }),
  });

export const getBundles = async (): Promise<JsonResponse<Bundle[]>> =>
  await fetch(`${getLocalURL()}/api/collection`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      category: 'preconfigured',
    }),
  });

export const getBundleBySlug = async (slug: string): Promise<JsonResponse<Bundle | string>> =>
  await fetch(`${getLocalURL()}/api/bundle/slug`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      slug,
    }),
  });

export const getProductBySlug = async (slug: string): Promise<JsonResponse<Product | string>> =>
  await fetch(`${getLocalURL()}/api/product/slug`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      slug,
    }),
  });

export interface SurroundingProducts {
  previous: Item | null;
  next: Item | null;
}

/**
 * Returns an object containing the products that surround the given product in a category. Either key may contain null
 * if the given product is the first, last, or only item in its category.
 *
 * This is currently being used to populate the product navigation component on product detail pages.
 */
export const getSurroundingProductsInCategory = async ({ category, id }: Item): Promise<SurroundingProducts> => {
  const callback = category === 'preconfigured' ? getBundles : getProducts;

  const response = await callback(category!);

  const products = (await response.json()).filter((product) =>
    product.category?.toLowerCase() === 'preconfigured' ? !product.is_retail || (product.displayable && !product.rental_bundle) : true
  );

  const index = products.findIndex((product) => product.id === id);

  return Promise.resolve({
    previous: products[index - 1] || null,
    next: products[index + 1] || null,
  });
};

export default productsApi;
